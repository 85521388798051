import React from "react";
import SideNav from "./SideNav";

function WithSideNav({ sideNavProps, title, children }) {
  return (
    <div className="">
      <div className="flex flex-col md:hidden w-11/12 mx-8">
        <div className="">
          <SideNav
            props={sideNavProps}
            className="flex flex-wrap space-x-3 mobile-nav"
            itemClassName=""
          />
        </div>
        <div className="mt-8 space-y-8">
          <h1 className="text-[48px] md:text-[58px] text-blue-100">{title}</h1>
          {children}
        </div>
      </div>
      <div className="hidden md:flex w-11/12">
        <div className="w-3/12 mt-32 ml-10">
          <SideNav props={sideNavProps} className="space-y-3" />
        </div>
        <div className="w-9/12 space-y-8">
          <h1 className="text-[48px] lg:text-[58px] text-blue-100">{title}</h1>
          {children}
        </div>
      </div>
    </div>
  );
}

export default WithSideNav;
